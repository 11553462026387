import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoint } from '../../shared/theme'
import { Content } from '../../elements/wyswyg'
import SinglePostInner from '../../elements/singlePostInner'
import H2 from '../../elements/typography/h2'
import SectionTransition from '../../elements/activeSectionDefault'

const Wrapper = styled(SectionTransition)`
	padding-bottom: 60px;

	@media ${breakpoint('tablet')} {
		padding-bottom: 136px;
	}

	@media ${breakpoint('desktop')} {
		padding-bottom: 160px;
	}
`

const Column = styled.div`
	grid-column: -1 / 1;

	@media ${breakpoint('tablet')} {
		grid-column: span 8;
	}

	@media ${breakpoint('desktop')} {
		grid-column: span 7;
	}
`

const Title = styled(H2)`
	margin-bottom: 2em;
`

const SingleInner = styled(Content)``

const SingleOneColumn = ({ heading, content, isActive }) => {
	if (heading == null && content == null) {
		return null
	}
	return (
		<Wrapper isActive={isActive}>
			<SinglePostInner>
				<Column>
					{heading && <Title>{heading}</Title>}
					<SingleInner dangerouslySetInnerHTML={{ __html: content }} />
				</Column>
			</SinglePostInner>
		</Wrapper>
	)
}

SingleOneColumn.propTypes = {
	content: PropTypes.string,
	title: PropTypes.string,
	isActive: PropTypes.bool,
}

SingleOneColumn.defaultProps = {
	content: '',
	title: '',
	isActive: true,
}

export default SingleOneColumn
