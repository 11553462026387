import React from 'react'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import larrWhite from '../../images/larr-white.svg'
import larrBlack from '../../images/larr-black.svg'

import { breakpoint } from '../../shared/theme'

export const Content = styled.div`
	h1 {
		font-weight: 700;
		margin-bottom: 1em;
	}

	* > a {
		text-decoration: underline;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	h3 {
		margin-bottom: 1.42em;

		@media ${breakpoint('tablet')} {
			margin-bottom: 1em;
		}

		& + ol,
		& + ul {
			padding-top: 2.25em;
		}
	}

	h4 {
		margin-bottom: 0.42em;
	}

	h5 {
		margin-bottom: 0.66em;
	}

	p {
		margin-bottom: 1.3em;
	}

	blockquote {
		p {
			margin-bottom: 1.09em;
		}
	}

	ol {
		counter-reset: list;

		li {
			position: relative;
			padding-left: 1.5em;
			counter-increment: list;

			&::before {
				top: 0;
				left: 0;
				display: inline-block;
				position: absolute;
				content: counter(list) '.';
			}
		}
	}

	ol,
	ul {
		margin-bottom: 1.3em;

		li {
			font-size: inherit;
			line-height: inherit;
		}
	}

	> *:last-child {
		margin-bottom: 0;
	}

	ul {
		li {
			position: relative;
			padding-left: 5.25em;
			margin-bottom: 1.25em;

			@media ${breakpoint('tablet')} {
				padding-left: 6.25em;
				margin-bottom: 1.7em;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&::before {
				content: '';
				background-image: ${(props) =>
					props.colorTheme === 'dark'
						? `url("${larrWhite}")`
						: `url("${larrBlack}")`};
				position: absolute;
				top: 0;
				left: 0;
				width: 3.125em;
				height: 1.7em;
				display: inline-block;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;

				@media ${breakpoint('tablet')} {
					width: 5em;
					height: 1.4em;
				}
			}
		}
	}
`

const Wyswyg = ({ children, colorTheme }) => {
	return (
		<Content className="content" colorTheme={colorTheme}>
			{children}
		</Content>
	)
}

Wyswyg.propTypes = {
	colorTheme: PropTypes.string,
}

Wyswyg.defaultProps = {
	colorTheme: 'light',
}

export default Wyswyg
