
// This is a temporary generated file. Changes to this file will be overwritten eventually!
import React from "react"
import { graphql } from 'gatsby'
import SEO from "../src/components/SEO"
import InviewWrapper from '../src/elements/inviewWrapper'
import FluidImage from '../src/components/FluidImage'
import PostRelated from '../src/components/postRelated'

import PostHeader from '../src/components/singlePostHeader'
import PostHero from '../src/components/singlePostHero'
import PostIntro from '../src/components/singlePostIntro'
import SharePost from '../src/components/sharePost'
import PostPageWrap from '../src/components/postPageWrap'
import PostContentWrap from '../src/components/postContentWrap'

// Sections
import SingleOneColumn from '../src/postLayouts/SingleOneColumn';
	

const Post = ({ pageContext, location }) => {
  const {
		post: {
			title,
			link,
			uri,
			id,
			categories,
			postBuilder,
			postHeader,
			postIntro,
			singleRelatedPosts,
			currentId
		},
	} = pageContext

  const layouts = postBuilder.modules || []

  return (
    <>
      <SEO title={title}/>
			<PostHero content={postHeader} />

			<PostPageWrap>
				<PostHeader title={title} />

				<InviewWrapper>
					<PostIntro categories={categories} copy={postIntro} />
				</InviewWrapper>
				
				<PostContentWrap>
					<InviewWrapper>
		      {
		        layouts.map((layout, index) => {
		          
		              if (layout.fieldGroupName === 'post_Postbuilder_Modules_SingleOneColumn') {
		                  return <SingleOneColumn {...layout} key={index} />;
		              }
		            
		        })
		      }
					</InviewWrapper>
				</PostContentWrap>
				
				<InviewWrapper>
					<SharePost title={title} location={location} />
				</InviewWrapper>
			</PostPageWrap>

			<PostRelated links={singleRelatedPosts} currentIndex={currentId} />

    </>
  )
}

export default Post
